<script lang="ts">import Prosjekter from "./Prosjekter.svelte";
import Hode from "./Hode.svelte";
</script>

<style>
    div {
        display: flex;
    }
</style>

<div>
    <Prosjekter />
    <Hode />
</div>
